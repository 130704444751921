import React from "react"
import { Form, Button } from "react-bootstrap"

const ContactForm = () => (
    <Form>
        <Form.Group controlId="formName" className="my-3">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Your name" />
        </Form.Group>
        <Form.Group controlId="formEmail" className="my-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Your email" />
            <Form.Text className="text-muted">
            We'll never share your email with anyone else.
            </Form.Text>
        </Form.Group>
        <Form.Group controlId="formDetails" className="my-3">
            <Form.Label>Project Details</Form.Label>
            <Form.Control as="textarea" placeholder="Tell us about your project..." rows={2} />
        </Form.Group>
        <Button className="em-bg-primary-blue border-0" type="submit">
        Submit
        </Button>
    </Form>
)

export default ContactForm;