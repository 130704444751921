import React from "react"
import { Card, Row, Col } from "react-bootstrap"
import { FaRegPaperPlane } from "react-icons/fa"


import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contact/contactForm"

const ContactPage = () => {
  return(
    <Layout>
      <SEO title="Contact" />
      <h1 className='text-center my-5'>Contact Us</h1>
      <Row className="justify-content-center mw-100 mx-0" >
          <Card className="shadow p-4 mb-5 bg-light bg-gradient" style={{width: '85%', maxWidth: '1000px'}}>
            <div className="mx-auto border rounded-circle p-4 mb-4 bg-white shadow" style={{width: '82px', height: '82px'}}>
              <FaRegPaperPlane className='text-secondary' size='2em'/>
            </div>
            <Row>
              <Col xs={12} md className='m-2'>
                <ContactForm />
              </Col>
              <Col className='m-2'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11601.569154520637!2d-80.327632!3d43.3688209!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbca8bfe23c815756!2sEsseltine%20Marketing!5e0!3m2!1sen!2sca!4v1611355334689!5m2!1sen!2sca" 
                width='auto' 
                height='auto' 
                frameBorder={0}
                style={{border: 0, height: '100%', width: '100%', float: 'right', minHeight: '400px'}} aria-hidden="false" title="Esseltine Marketing on the map" />
              </Col>
            </Row>
          </Card>
      </Row>
    </Layout>
  )
}

export default ContactPage